<template>
  <div class="mt-5">
    <div class="vx-col  auto  m-5 mb-base">
      <vs-card>
        <div class="p-6 w-full md:w-1/2">
          <div class="vx-col w-full mt-5 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64 flex items-center">
                <img :src="logo" alt="img" class="responsive" />
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex mt-5">
                <input
                  type="file"
                  class="hidden"
                  ref="updateImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button
                  class="mr-4"
                  href="#"
                  @click="$refs.updateImgInput.click()"
                  >Update Logo</vs-button
                >
                <vs-button color="danger" @click="logo = null"
                  >Remove Logo</vs-button
                >
              </div>
            </template>
          </div>

          <div class="upload-img mt-5" v-if="!logo">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button @click="$refs.uploadImgInput.click()"
              >Upload Logo</vs-button
            >
          </div>
          <!-- Director-->
          <div class="vx-col w-full mt-5">
            <label class="block mb-2">Director Name</label>
            <v-select
              :options="directorOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="directorFilter"
              class="mb-4 md:mb-0"
            />
          </div>
          <!-- NAME -->
          <div class="vx-col w-full mt-5">
            <vs-input
              label="Centre Name"
              v-model="companyName"
              class="w-full"
              name="companyName"
              v-validate="'required'"
              data-vv-as="Centre name"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyName')"
              >{{ errors.first("companyName") }}</span
            >
          </div>

          <div class="vx-col w-full mt-5">
            <vs-input
              label="Phone Number"
              v-model="contactNumber"
              class="mt-5 w-full"
              name="contactNumber"
              v-validate="'required|numeric'"
              data-vv-as="phone number"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contactNumber')"
              >{{ errors.first("contactNumber") }}</span
            >
          </div>

          <div class="vx-col w-full mt-5">
            <vs-input
              class="w-full mt-5"
              name="email"
              v-validate="'required|email'"
              label="Email"
              v-model="email"
              data-vv-as="Email"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>

          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">Country</label>
            <v-select
              :options="countryOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="countryFilter"
              @input="onStateChange"
              class="mb-4 md:mb-0"
              data-vv-as="Country"
              name="country"
            />
            <span class="text-danger text-sm" v-show="errors.has('country')">{{
            errors.first("country")
          }}
            </span>
          </div>

          <div class="vx-col w-full mt-5">
            <label class="vs-input--label">State</label>
            <v-select
              :options="stateOptions"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="stateFilter"
              @input="onStateChange"
              class="mb-4 md:mb-0"
              data-vv-as="State"
              name="state"
            />
            <span class="text-danger text-sm" v-show="errors.has('state')">{{
            errors.first("state")
          }}
            </span>
          </div>

          <div class="md:flex" vs-justify="left" vs-align="left">
            <div class="w-full md:w-1/2 mt-5 md:mr-5">
              <label class="vs-input--label">Street Address</label>
              <input
                type="text"
                placeholder
                ref="autocomplete"
                v-validate="'required'"
                data-vv-as="Street Address"
                name="streetAddress"
                v-model="streetAddress"
                class="vs-inputx vs-input--input normal"
                :class="{ hasValue: streetAddress }"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 md:ml-5">
              <span
                class="text-danger text-sm"
                v-show="errors.has('streetAddress')"
                >{{ errors.first("streetAddress") }}</span
              >

              <vs-input
                label="Town/City"
                v-model="suburb"
                class="mt-5 w-full"
                name="suburb"
                v-validate="'required'"
                data-vv-as="Town"
              />
              <span class="text-danger text-sm" v-show="errors.has('suburb')">{{
                errors.first("suburb")
              }}</span>
            </div>
          </div>

          <div class="vx-col w-full mt-5">
            <vs-input
              label="Postcode"
              v-model="postCode"
              class="mt-5 w-full"
              name="postCode"
              v-validate="'required'"
              data-vv-as="Post Code"
            />
            <span class="text-danger text-sm" v-show="errors.has('postCode')">{{
              errors.first("postCode")
            }}</span>
          </div>

          <div class="flex flex-wrap items-center p-6 pr-0">
            <vs-button
              class="ml-auto"
              @click="submitData"
              :disabled="!isFormValid"
              >Save</vs-button
            >
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  async
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o&libraries=places"
></script>
<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dataId: null,
      directorId: "",
      companyName: "",
      contactNumber: "",
      fullName: "",
      email: "",
      logo: null,
      image: null,
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      streetAddress: "",
      suburb: "",
      postCode: "",
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      customError: "",
      address: {
        displayAddress: "",
        suburb: "",
        state: "",
        street: "",
        streetNumber: "",
        postCode: "",
        location: {
          type: "point",
          coordinates: []
        },
        addressData: []
      },
      checkDisplayAddress: "",
      directorOptions: [],
      directorFilter: { label: "All", value: "all" }
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.companyName &&
        this.contactNumber &&
        this.email &&
        this.logo &&
        // this.countryFilter.value != "all" &&
        // this.stateFilter.value != "all" &&
        this.directorFilter.value != "all" &&
        this.streetAddress &&
        this.suburb &&
        this.postCode
      );
    }
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(async () => {
      this.autocomplete = await new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ["geocode"] }
      );

      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        console.log(place);
        this.errors.remove("streetAddress");
        let ac = place.address_components;
        this.address.addressData = place.address_components;
        this.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.address.displayAddress = place.formatted_address;
        this.streetAddress = place.formatted_address;
        this.address.location.coordinates[0] = lon;
        this.address.location.coordinates[1] = lat;
      });
    });
  },
  methods: {
    ...mapActions("center", [
      "updateCenter",
      "addCenter",
      "imageUpload",
      "getAllCountries",
      "getDirectorList"
    ]),
    place_changed(a) {},
    fetchDirectorList() {
      this.getDirectorList().then(res => {
        let data = res.data.data;
        this.directorOptions = data.map(item => {
          return {
            label: item.fullName,
            value: item._id
          };
        });
      });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    onStateChange() {
      this.errors.clear();
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          if (this.countryFilter.value == "all") {
            this.errors.add({
                  field: "country",
                  msg: "Country can't be 'All'"
            });
          return;
          }
          if (this.stateFilter.value == "all") {
            this.errors.add({
                  field: "state",
                  msg: "State can't be 'All'"
            });
          return;
          }
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("name", this.companyName);
          data.append("phoneNumber", this.contactNumber);
          data.append("directorId", this.directorFilter.value);
          data.append("fullName", this.directorFilter.label);
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("state", this.stateFilter.value);
          data.append("displayAddress", this.streetAddress);
          let sA = this.streetAddress.split(",");
          data.append("streetAddress", sA[0]);
          data.append("suburb", this.suburb);
          data.append("postCode", this.postCode);
          data.append("location", JSON.stringify(this.address.location));
          data.append("country", this.countryFilter.value);

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateDirectorCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Centre Updated successfully.",
                    "success"
                  );
                  data.country = this.countryFilter.value;
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
              });
          } else {
            if (this.logo == null) {
              this.customError = "Image field is required.";
              return;
            }
            this.$vs.loading();
            this.addCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.showMessage(
                    "Success",
                    "Centre added successfully.",
                    "success"
                  );
                  this.$router.push({ name: "learning-centre-list" });
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                this.showMessage("Failed", err.response.data.message, "danger");

                // this.showMessage("Failed", "Failed to add centre", "danger");
              });
          }
        }
      });
    }
  },
  created() {
    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch(err => {});
    this.fetchDirectorList();
  },
  components: {
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
